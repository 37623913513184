@tailwind base;
@layer base {
  /* FONTS VARIABLES */
  [data-font-theme='theme-sportsbet'] {
    --font-theme: var(--averta);
  }
}

@font-face {
  font-family: 'Averta Std';
  font-style: normal;
  font-weight: 400;
  unicode-range: 'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD';
  src: url(/public-casino/fonts/regular.woff2) format('woff2');
  font-display: swap;
}
@font-face {
  font-family: 'Averta Std';
  font-style: normal;
  font-weight: 600;
  unicode-range: 'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD';
  src: url(/public-casino/fonts/semibold.woff2) format('woff2');
  font-display: swap;
}
