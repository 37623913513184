@tailwind base;
@tailwind components;
@tailwind utilities;
@import '~@heathmont/moon-themes-tw/lib/bitcasino.css';
@import '~@heathmont/moon-themes-tw/lib/livecasino.css';
@import '~@heathmont/moon-themes-tw/lib/sportsbet.css';
@import '~@heathmont/moon-themes-tw/lib/empire.css';
@import '~@heathmont/moon-themes-tw/lib/bombayClub.css';

@import 'themes/theme-bitcasino.css';
@import 'themes/theme-empire.css';
@import 'themes/theme-sportsbet.css';

.theme-empire-light .empire-logo {
  color: #4828c5;
}

@layer components {
  .inline-container {
    @apply flex flex-wrap items-center justify-start [&_li]:list-none;
  }
}

[id^='telegram-login'] {
  display: none;
}
